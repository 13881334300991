import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/Common/Layout";
import CompanyLogo from "../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../components/Website/ProjectsNavigation";
import ServicePellet from "../../components/Website/ServicePellet";
import ProjectImagesSwiper from "../../components/Website/ProjectImagesSwiper";

const Skippit = ({ data }) => {
  const {
    SkippitLiveVideo,
    SkippitMainNavigation,
    SkippitOnboarding,
    SkippitMatches,
    SkippitProfile,
    SkippitHeader,
  } = data;
  const [modalVisible, setModalVisible] = useState(false);

  function getImageData(data) {
    return data.edges.map((edge) => edge.node.childImageSharp.fluid);
  }

  const liveVideoImages = getImageData(SkippitLiveVideo);
  const mainAppImages = getImageData(SkippitMainNavigation);
  const onboardingImages = getImageData(SkippitOnboarding);
  const matchesImages = getImageData(SkippitMatches);
  const profileImages = getImageData(SkippitProfile);
  const headerImage = getImageData(SkippitHeader);

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      title="Brocode Studio || Skippit"
      description="Skippit is a new type of first date that uses video to
      change the way people use and experience dating apps"
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={6} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Skippit App</h2>
                  </div>
                  <ServicePellet service="UI & UX" />
                  <ServicePellet service="Branding" />
                  <ServicePellet service="Development" />
                  <Row className="my-md-4 my-1 d-md-block d-none mx-0">
                    <p className="wow move-up  mw-100 mw-md-75 mb--20">
                      A new kind of first date <span role="img">❤️</span>
                    </p>
                    <p className="wow move-up  mw-100 mw-md-75 mb--20">
                      Skippit is a new type of first date that uses video to
                      change they way people use and experience dating apps.
                    </p>
                    <p className="wow move-up  mw-100 mw-md-75 mb--20">
                      Calling to replace the expensive Ubers, awkward first
                      drinks, and cat-fishing that’s common in most dating apps.
                    </p>
                    <p className="wow move-up  mw-100 mw-md-75 mb--20">
                      kippit was launched in EUA. In the first week of launch
                      reached number 66 on the App store.
                    </p>
                  </Row>
                </div>
              </Fade>
            </Col>
            <Col md={6} xs={12}>
              <Img fluid={headerImage[0]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 d-md-none d-block mx-0">
              <p className="wow move-up  mw-100 mw-md-75 mb--20">
                A new kind of first date <span role="img">❤️</span>
              </p>
              <p className="wow move-up  mw-100 mw-md-75 mb--20">
                Skippit is a new type of first date that uses video to change
                they way people use and experience dating apps.
              </p>
              <p className="wow move-up  mw-100 mw-md-75 mb--20">
                Calling to replace the expensive Ubers, awkward first drinks,
                and cat-fishing that’s common in most dating apps.
              </p>
              <p className="wow move-up  mw-100 mw-md-75 mb--20">
                Skippit was launched in EUA. In the first week of launch reached
                number 66 on the App store.
              </p>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 ">
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={onboardingImages[0]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={onboardingImages[1]} />
              </Col>
              <Col
                md={6}
                xs={12}
                style={{ padding: "0 10%", alignSelf: "center" }}
              >
                <div class="portfolio-h3">Onboarding</div>
                <p class="portfolio-paragraph d-md-block d-none">
                  Help the user ease into the product’s experience. It can be as
                  simple as a greeting and an explanation or as complex as a
                  series of guided tasks for users to complete. So long as
                  you’re providing your users with an on-ramp to the benefits of
                  your product, you’re setting them up for success.
                </p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 d-none d-md-flex">
              <Col md={3} xs={12}>
                <Img fluid={onboardingImages[2]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={onboardingImages[3]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={onboardingImages[4]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={onboardingImages[5]} />
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={onboardingImages} />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-md-4 my-1 justify-content-center d-none d-md-flex">
              <Col md={3} xs={6}>
                <Img fluid={onboardingImages[6]} />
              </Col>
              <Col md={3} xs={7}>
                <Img fluid={onboardingImages[7]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 justify-content-center text-center">
              <Col md={9} xs={12}>
                <div class="portfolio-h3 ">Main navigation</div>
                <p class="portfolio-paragraph d-md-block d-none">
                  your product, you’re setting them up for success.After
                  Onboarding users will land on Skippit main screen where they
                  can use a simple gesture navigation to navigate between
                  screens. Swipe left will go to Matches screen, swipe right to
                  Profile screen and swipe up to Discovery screen.
                </p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 d-none d-md-flex">
              <Col md={3} xs={12}>
                <Img fluid={mainAppImages[0]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={mainAppImages[1]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={mainAppImages[2]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={mainAppImages[3]} />
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={mainAppImages} />
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-5 justify-content-center text-center">
              <Col md={9} xs={12}>
                <div class="portfolio-h3">Live video dates - Happy Hour</div>
                <p class="portfolio-paragraph d-md-block d-none">
                  Skippit uses video to change they way people use and
                  experience dating apps. By registering on the Happy Hour,
                  users will have a list of video dates.
                </p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 justify-content-center d-none d-md-flex">
              <Col md={3} xs={6}>
                <Img fluid={liveVideoImages[0]} />
              </Col>
              <Col md={3} xs={7}>
                <Img fluid={liveVideoImages[1]} />
              </Col>
              <Col md={3} xs={7}>
                <Img fluid={liveVideoImages[2]} />
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={liveVideoImages} />
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 d-none d-md-flex">
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[3]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[4]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[5]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[6]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-md-4 my-1 justify-content-center d-none d-md-flex">
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[7]} />
              </Col>
              <Col md={3} xs={12}>
                <Img fluid={liveVideoImages[8]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-5">
              <Col md={3} xs={12} style={{ alignSelf: "center" }}>
                <div class="portfolio-h3">Matches/Chat</div>
                <p class="portfolio-paragraph d-md-block d-none">
                  Through Matches screen users can access chat to send messages
                  and have video calls.
                </p>
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={matchesImages[0]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={matchesImages[1]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={matchesImages[2]} />
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={matchesImages} />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5">
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={profileImages[0]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={profileImages[1]} />
              </Col>
              <Col md={3} xs={12} className="d-md-block d-none">
                <Img fluid={profileImages[2]} />
              </Col>
              <Col md={3} xs={12} style={{ alignSelf: "center" }}>
                <div class="portfolio-h3">Profile</div>
                <p class="portfolio-paragraph d-md-block d-none">
                  Through Profile screen users can edit preferences, edit
                  profile and contact support.
                </p>
              </Col>
            </Row>
            <ProjectImagesSwiper imagesList={profileImages} />
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="skippit" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{
              name: "Sublime Comporta",
              link: "/work/sublimecomporta",
            }}
            prevProject={{
              name: "linq",
              link: "/work/linq",
            }}
            selectedLanguage="en"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Skippit;

export const SkippitQuery = graphql`
  query {
    SkippitLiveVideo: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/live_video/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitMainNavigation: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/main_navigation/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitMatches: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/matches/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitOnboarding: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/onboarding/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitProfile: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/profile/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    SkippitHeader: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/skippitapp/header/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
